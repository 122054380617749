<template>
  <div class="spinner-border spinner-border-sm mx-1" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
}
</script>
